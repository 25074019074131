<template>
  <v-col>
    <v-card class="px-8 py-12" flat>
      <v-row>
        <v-col :cols="$vuetify.breakpoint.mdAndUp ? '4' : '12'">
          <v-row class="subheading">제안 가능 금액</v-row>
          <v-row class="caption">즉시 이용가능한 잔액입니다.<br></v-row>
          <v-row class="display-1 secondary--text text-center mt-10">
            <v-col>
              ₩{{availPoints | numFormat}}
            </v-col>
          </v-row>
        </v-col>
        <v-col :cols="$vuetify.breakpoint.mdAndUp ? '4' : '12'">
          <v-row class="subheading">제안 대기 금액</v-row>
          <v-row class="caption">광고 제안이 수락되는 경우 제안 대기중 금액 내에서 소진되며,<br> 제안이 거절되는 경우 잔액으로 충전됩니다.</v-row>
          <v-row class="display-1 text-center mt-5">
            <v-col>
              ₩{{waitPoints | numFormat}}
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card>
    <v-divider></v-divider>
    <v-card flat class="pa-8">
      <v-row class="subheading">잔액 충전하기</v-row>
      <v-row class="caption">현재 잔액 충전은 카드로만 가능합니다.</v-row>
      <v-divider class="my-4"></v-divider>
      <v-row class="body-2">
        <v-col :cols="$vuetify.breakpoint.mdAndUp ? '5' : '12'">
          <div class="mb-2">충전금액</div>
          <v-chip v-for="(amount, idx) in [5, 10, 50, 100]" :key="idx"
            @click="price = price + (amount * 10000)">
            +{{amount}}만원
          </v-chip>
          <v-text-field v-model.number="price" name="price" type="number" suffix="원" class="mt-2 mb-6"
                        hint="충전하실 금액을 추가하거나 직접 입력해주세요." persistent-hint single-line outlined required>직접 입력</v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <div class="mb-2">결제 금액</div>
          <div class="secondary--text subheading">₩{{Math.floor(price * 1.1) | numFormat}}</div>
          <div class="my-4" style="color:#757575">결제 금액은 부가세 10%가 포함된 금액입니다.</div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <div class="mb-8 caption secondary--text">
            <!-- <ul>
              <li>세금계산서는 매월 초 (3-5일 사이)에 발행됩니다.</li>
              <li>세금계산서는 해당 월의 충전 금액이 아니라 전체 캠페인의 소진 금액을 통합한 금액에 대해 1장으로 발행됩니다.</li>
              <li>인플루언서의 콘텐츠 등록이 완료되어야 금액 소진됩니다.<br>(5월에 100만원의 제안금액을 사용하고, 5월 내 콘텐츠 등록이 완료된 건이 50만원이라면, 6월 초에 50만원에 대한 세금계산서가 발행됩니다.)</li>
            </ul> -->
          </div>
        </v-col>
      </v-row>
      <v-btn color="primary" @click="pay()" style="margin:0">결제하기</v-btn>
<!--      <v-btn :href="baseUrl + '/payment/makeRequest.html'" target="_blank"></v-btn>-->
<!--      <v-dialog v-model="dialog" persistent width="820px">-->
<!--        <template v-slot:activator="{ on, attrs }">-->
<!--          <v-btn color="primary" dark v-bind="attrs" v-on="on" @click="pay()">-->
<!--            Pay-->
<!--          </v-btn>-->
<!--        </template>-->
<!--        <v-card>-->
<!--          <v-card-text class="pa-0" style="height: 600px; margin-left: -8px;">-->
<!--            <iframe :src="baseUrl + '/payment/makeRequest.html'"-->
<!--                    @close="dialog=false"-->
<!--                    frameborder="0" style="width:100%; height:100%"></iframe>-->
<!--          </v-card-text>-->
<!--        </v-card>-->
<!--      </v-dialog>-->
    </v-card>
  </v-col>
</template>

<script>
  import userMixin from '@/components/shared/userMixin';
  import campaignMixin from "@/components/shared/campaignMixin";
  import BootPay from "bootpay-js";
  import {EventBus} from "@/components/shared/event-bus";

  export default {
    name: "Payment",
    mixins: [userMixin, campaignMixin],
    data() {
      return {
        baseUrl: process.env.VUE_APP_API_URL,
        dialog: false,
        price: 0,
        propositions: [],
        points: [{balance: 0}]
      };
    },
    methods: {
      pay() {
        let price = this._.isNumber(this.price) ? this.price : Number(this.price.replace(/[^\d]/g, ''));
        let payPrice = Math.floor(this.price * 1.1);
        BootPay.request({
          price: payPrice, //실제 결제되는 가격
          application_id: "5f20860202f57e0033305324",
          name: '인플루언서 마케팅 상품', //결제창에서 보여질 이름
          pg: 'inicis',
          method: 'card',
          show_agree_window: 0, // 부트페이 정보 동의 창 보이기 여부
          items: [
            {
              item_name: '인플루언서 마케팅 상품', //상품명
              qty: 1,
              unique: '0',
              price: payPrice,
              cat1: '마케팅',
              cat2: '마케팅',
              cat3: '마케팅',
            }
          ],
          user_info: {
            username: this.user.name,
            email: this.user.email,
            addr: '',
            phone: this.user.contacts.replace(/[^0-9]/g, '')
          },
          order_id: this.user.id + '_matching_' + new Date().getTime(),
          params: {date: new Date(), companyId: this.user.id},
          extra: {
            theme: 'purple'
          }
        }).error((data) => {
          console.log(data);
          this.$store.commit('setMsg', data.msg);
          this.$store.commit('setShowSnackbar', true);
        }).cancel((data) => {
          console.log(data);
        }).confirm((data) => {
          if (data.params.companyId === this.user.id) {
            BootPay.transactionConfirm(data);
          } else {
            BootPay.removePaymentWindow();
          }
        }).close((data) => {
          console.log(data);
        }).done(async (data) => {
          let charge = {
            companyId: data.params.companyId,
            card: data.card_name,
            amount: price,//data.price,
            status: data.status,
            method: data.method,
            order: data.order_id,
            receiptId: data.receipt_id,
            receiptUrl: data.receipt_url
          }
          try {
            let res = await this.axios.post('/company/user/charge', charge);
            if (res.status === 200) {
              this.points.unshift(res.data);
            }
          }
          catch (e) {
            console.log(e)
          }
          if (this.user) {
            EventBus.$emit("update:point");
          }
        });
      }
    },
    mounted() {
      this.$nextTick(async () => {
        // this.$inipay.initialize();
        if (this.campaigns.length > 0) {
          let filterCampaign = '';
          this.campaigns.map((campaign) => {
            filterCampaign += ('&campaignId[]=' + campaign.id);
          });
          this.axios.get('/campaign/proposition?status=1' + filterCampaign).then(propositions => {
            this.propositions = propositions.data.propositions
          }).catch((err) => {
            console.log(err)
          });

          let points = await this.axios.get(`company/user/point?companyId=${this.user.id}&orderBy=id&direction=desc`);
          if (points.data.total > 0) {
            this.points = points.data.companyPoints;
          }
        }
      });
    }
  };
</script>
